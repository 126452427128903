<template>
  <div class="modal" id="addNewRecurirngModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Recurring
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div v-if="ui.loading === false">
          <div class="modal-body-container">
            <div class="col-md-6">
              <div class="form-group">
                <label for="fieldAgent">
                  Agent
                </label>
                <multiselect v-model="agentSelected"
                             placeholder="Select Agent"
                             id="fieldAgent"
                             :options="formData.agents"
                             :searchable="true"
                             :close-on-select="true"
                             :allow-empty="false"
                             :show-labels="false">
                </multiselect>

              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="form-group">
              <label for="fieldAmount">
                Amount
              </label>
              <input type="number" class="form-control"
                     v-model="amount" id="fieldAmount">
            </div>
            <div class="form-group full-width mt-3">
              <label for="fieldMemo">
                Memo
              </label>
              <textarea id="fieldMemo" cols="30" rows="6" class="form-control"></textarea>
            </div>

            <div class="form-group mt-3">
              <label for="fieldVendor">
                Vendor
              </label>
              <multiselect v-model="vendor"
                           placeholder="Select Vendor"
                           id="fieldVendor"
                           :options="formData.vendor"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>

            </div>
            <div class="form-group mt-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="fieldWeekly" value="80,059.71" v-model="frequency">
                <label class="form-check-label" for="fieldWeekly">
                  Weekly
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="fieldMonthly" value="otherAmount" v-model="frequency">
                <label class="form-check-label" for="fieldMonthly">
                  Monthly
                </label>
              </div>
            </div>
            <div class="form-group col-md-6 mt-3">
              <label for="fieldDate">
                Start Date
              </label>
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                class="daterange-control"
                id="fieldDate"
                opens="center"
                :singleDatePicker="true"
                :auto-apply="true"
                :timePicker="false"
                :time-picker24-hour="false"
                :ranges="false"
                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
              >

                <template v-slot:input="picker">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  <img src="@/assets/icons/icon-calender.svg" alt="">
                </template>
              </date-range-picker>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start" v-if="ui.loading === false">
        <div>
          <button class="btn btn-primary" @click="saveRecurring">
          <span v-if="ui.saving === true"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
            <span v-if="ui.saving === false">
            Save Recurring
          </span>
          </button>
          <button class="btn btn-cancel ms-2" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
  name: 'EditRecurringExpense',
  components: { Multiselect, DateRangePicker },
  props: {
    expenseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      agentSelected: '',
      amount: '',
      memo: '',
      vendor: '',
      frequency: '',
      formData: {
        agents: [],
        vendor: []
      },
      dateRange: {
        startDate: '2019-12-10',
        endDate: '2019-05-02'
      },
      ui: {
        saving: false,
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    },
    saveRecurring() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  },
  beforeMount() {
    this.getData();
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    }
  }

}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

.form-check-input[type="radio"] {
  max-width: 18px;
  height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  &:checked {
    background-color: rgba(1,122,255,1);
    border-color: rgba(1,122,255,1);
  }
}
.form-check {
  margin: 0;
}
</style>
