<template>
  <div>
    <div class="controls-panel">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 col-md-3 pe-4 ps-3">
              <div class="row">
                <div class="col-3">
                  <button class="btn btn-control p-0"
                          content="Select"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <div class="form-group">
                      <input type="checkbox" name=""
                             class="form-check-input m-0"
                             @click="selectAll(selectAllCheckbox)"
                             v-model="selectAllCheckbox">
                    </div>
                  </button>
                </div>
                <div class="col-3">
                  <button class="btn btn-control"
                          @click="deleteSelected"
                          content="Delete"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <img src="@/assets/icons/bold-icon delete.svg" alt="Delete">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-end mt-3 mt-md-0">
          <div class="d-flex align-items-center justify-content-end">
              <span class="color-light-grey border-right-light pe-3 me-3 selected-count">
                {{ selectedCount }} selected
              </span>
            <pagination
              class="justify-content-end"
              :from="10"
              :to="20"
              :total="300"
              :page="1"
              :per-page="2" />
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="row mt-2" v-if="ui.loading === false">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Amount</th>
              <th>Memo</th>
              <th>Vendor</th>
              <th>Frequency</th>
              <th>First Charge On</th>
              <th>Next Charge On</th>
              <th>Agents</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(expense, index) in expenseRecurring" :key="index">
              <td>
                <div class="form-group">
                  <input type="checkbox" name=""
                         @click="countSelected(expense.checked); expense.checked = !expense.checked"
                         v-model="expense.checked"
                         id="" class="form-check-input m-0" >
                </div>
              </td>
              <td>$110.00</td>
              <td>Rick Flores email expense</td>
              <td>godaddy</td>
              <td>monthly</td>
              <td>May, 26 2020</td>
              <td>Nov, 26 2020</td>
              <td>John</td>
              <td>
                <div class="row">
                  <div class="col-3" @click="modals.showRecurringExpense = true; expenseId = expense.id">
                    <button class="btn btn-outline-dark w-100">
                      Show
                    </button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-outline-dark w-100" @click="modals.editRecurringExpense = true; expenseId = expense.id">
                      Edit
                    </button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-outline-dark w-100" @click="deleteRecurring(expense.id)">
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :from="10"
        :to="20"
        :total="300"
        :page="1"
        :per-page="2" />
    </div>
    <show-recurring-expense-modal v-if="modals.showRecurringExpense === true"
                                  :expenseId="expenseId"
                                  @editRecurring="modals.editRecurringExpense = true"
                                  @close="modals.showRecurringExpense = false" />

    <edit-recurring-expense v-if="modals.editRecurringExpense === true"
                            :expenseId="expenseId"
                            @close="modals.editRecurringExpense = false" />
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";
import ShowRecurringExpenseModal from "./modals/ShowRecurringExpenseModal";
import EditRecurringExpense from "./modals/EditRecurringExpense";

export default {
  name: 'ExpenseRecurring',
  components: {EditRecurringExpense,Pagination, ShowRecurringExpenseModal },
  data() {
    return {
      searchField: '',
      selectAllCheckbox: false,
      editAllow: false,
      selectedCount: 0,
      expenseRecurring: [],
      expenseId: null,
      modals: {
        showRecurringExpense: false,
        editRecurringExpense: false
      },
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
        this.expenseRecurring = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}]
      }, 1000)
    },
    selectAll(val) {
      if (val === false) {
        this.expenseRecurring.map((expense) => {
          expense.checked = true;
          this.selectedCount++;
        })
      } else {
        this.expenseRecurring.map((expense) => {
          expense.checked = false;
          this.selectedCount--;
        })
      }
    },
    countSelected(val) {
      if (val === false) {
        this.selectedCount++;
        this.selectAllCheckbox = true;
        if (this.selectedCount === 1) {
          this.editAllow = true;
        }
      } else {
        this.selectedCount--;
        this.editAllow = false;
        if (this.selectedCount === 0) {
          this.selectAllCheckbox = false;
        }
      }
    },
    deleteSelected() {
      const deleteRequests = this.expenseRecurring.filter((request) => {
        return request.checked === true
      });

      if (deleteRequests.length > 0) {
        this.ui.loading = true;
        setTimeout(() => {
          this.ui.loading = false;
          this.selectAllCheckbox = false;
        }, 1000);
      } else {
        alert('Pls select')
      }
    },
    deleteRecurring(id) {
      console.log(id)
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    page() {
      return this.$route.name
    },
  }
}
</script>

<style lang="scss" scoped>
table.table {
  min-width: 1600px;
  tr {
    vertical-align: middle;
  }
  .status {
    display: flex;
    justify-content: center;
    max-width: 95px;
    width: 100%;
    padding: 5px 19px;
    border-radius: 8px;
    &.unverified {
      border: 1px solid rgba(0, 7, 46, 0.2);
      background-color: rgba(0, 7, 46, 0.05);
      color: black;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
    &.verified {
      border: 1px solid rgba(38,111,229,0.4);
      border-radius: 8px;
      background-color: rgba(38,111,229,0.05);
      color: rgba(38,111,229,1);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}
</style>
