<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Recurring Expense
        </h3>
        <button class="btn btn-control" @click="$emit('editRecurring')">
          <img src="@/assets/icons/icon-edit-normal.svg" alt="">
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container" v-if="ui.loading === false">
          <div class="table">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    Amount
                  </td>
                  <td>$110.00</td>
                </tr>
                <tr>
                  <td>Memo</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Frequency
                  </td>
                  <td>monthly</td>
                </tr>
                <tr>
                  <td>First Charge On</td>
                  <td>Nov, 26 2020</td>
                </tr>
                <tr>
                  <td>Next Charge On</td>
                  <td>Nov, 26 2020</td>
                </tr>
                <tr>
                  <td>Agents</td>
                  <td>John</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
        <button class="btn btn-control">
          <img src="@/assets/icons/icon-delete.svg" alt="Delete">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecurringExpenseModal',
  props: {
    expenseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>
